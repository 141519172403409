/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "../../../../../node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "../../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "../../../../../node_modules/@ng-select/ng-select/scss/default.theme.scss";
@import "./colors.scss";

@import "remixicon/fonts/remixicon.css";

@import "icons/mycollection/font/flaticon_mycollection.scss";

@import "ngx-toastr/toastr-bs5-alert";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

img,
video {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: black;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p {
  margin-bottom: 0 !important;
}

.p-6 {
  padding: 2px 15px;
}

.w-10 {
  width: 100px;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-25 {
  font-size: 25px;
}

.lh-1 {
  font-family: flaticon_mycollection !important;
  line-height: 1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.custom-sidebar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #bbbbbb;
    border-radius: 15px;
  }
}

.status-card {
  .number {
    font-size: 37.198px;
    font-weight: 600;
  }

  .status {
    // width: 180px;
    h5 {
      // text-overflow: ellipsis;
      font-weight: 500;
      font-size: 19px;
      // overflow: hidden;
      // white-space: nowrap;
    }
  }

  .icon {
    width: 47px;
    height: 47px;
    line-height: 47px;

    i {
      font-size: 27px;
    }
  }
}

.text-dark {
  color: $dark !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.card {
  // overflow: hidden;
  border: 1px solid $gray;
  border-radius: 15px;
  margin-bottom: 1rem;

  .card-header {
    background-color: #fff;
    border: 0;
    border-radius: 15px;
  }
}

/* start global form inputs design*/
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
  background-color: #ff2c2c;
  border-color: #ff2c2c;
}

.form-switch .form-check-input:checked {
  background-color: #28c76f;
  border-color: #28c76f;
}

.form-switch .form-check-input:focus {
  box-shadow: none;
}

.form-control {
  border: 1px solid $gray;
}
.ng-select-container {
  height: 39px !important;
  border: 1px solid $gray !important;
  padding: 6px 0 !important;
  border-radius: 0.375rem !important;
}

.form-label {
  color: #19191c;
  font-weight: 600;
}

.sort-select {
  padding-left: 40px;
  padding-right: 5px;
  background-color: #fff;
  background-image: url("../assets/images/sort.png");
  background-repeat: no-repeat;
  background-position: center left 10px;
  background-size: auto;
}

input[name="date"],
input[name="search"] {
  padding-left: 40px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center left 10px;
}

input[name="search"] {
  background-image: url("../assets/images/search.png");
}

input[name="date"] {
  background-image: url("../assets/images/calendar.png");
}

.filter-btn {
  border-radius: 0.375rem;
  border: 1px solid $gray;
  color: $dark;

  &:hover {
    border: 1px solid $gray;
  }
}

// .form-control,
// .ng-select.ng-select-single .ng-select-container {
//   min-height: 45px;
// }

// .btn {
//   min-height: 45px;
// }
.confirm-products-modal.modal-dialog {
  max-width: 95%;
  min-height: 90vh;
  .modal-content {
    min-height: 90vh;
    overflow-y: auto;
  }
}

.btn-success {
  background-color: #28c76f;
  border-color: #28c76f;
}

.btn-outline-secondary {
  border-color: #ebebeb !important;
  color: #151d48;
  font-weight: 500;
}

// .btn-outline-transparent {
//   background-color: transparent;
//   border-color: transparent;
//   box-shadow: none;
//   + .btn-check:checked + .btn,
//   :not(.btn-check) + .btn:active,
//   .btn:first-child:active,
//   .btn.active,
//   .btn.show {
//   }
// }

.bg-success {
  background-color: rgba(40, 199, 111, 0.16) !important;
}

.bg-success-light {
  color: #02d3a1;
  background-color: #ebfffa;
}

.bg-pending {
  color: #ff9f43;
  background-color: rgba(255, 159, 67, 0.16);
}

.bg-yellow {
  color: #9db121;
  background-color: #faffdd;
}

.bg-blue {
  background-color: #e6f8ff;
  color: #00b2ff;
}

.bg-danger {
  background-color: #fcdddd !important;
}

.bg-purple {
  background-color: rgba(115, 103, 240, 0.16);
}

.btn-primary,
.btn-check:checked + .btn-primary,
:not(.btn-check) + .btn-primary:active,
.btn-primary:first-child:active,
.btn-primary.active,
.btn-primary:hover,
.btn-primary.show {
  // background-color: linear-gradient(230deg, #000 0%, rgba(0, 0, 0, 0) 50%);;
  background: $primaryGradient;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 500;

  i {
    font-size: 18px;
  }
}

.btn-primary {
  &:hover {
    border: 1px solid #000 !important;
    color: #000 !important;
    background: transparent !important;
  }
}

/* end global form inputs design*/
/* start global tables design*/
$border-option: 1px solid #ebebeb;

.table-filter {
  input[type="text"] {
    &::placeholder {
      color: rgba(227, 227, 227, 1);
    }
  }

  button {
    font-weight: 500;
  }

  i {
    line-height: 1px;
    font-weight: 600 !important;
    font-size: 15px;
    margin-right: 0.375rem;
  }
}

.product-card {
  .product-img {
    height: 214px;
    overflow: hidden;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    position: relative;
    .view-icon {
      background-color: #f8f7fa;
      position: absolute;
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      border-radius: 9.73px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 15px;
        line-height: 15px;
        color: #4b465c;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .product-info {
    .product-name {
      font-size: 15px;
      font-weight: 500;
      width: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .offer {
      background-color: #d3fee6;
      color: #28c76f;
      padding: 5px;
      border-radius: 5px;
      font-size: 13px;
    }
    .short-desc {
      font-size: 12px;
      color: #4b465c;
      width: 140px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.cart-icon {
  position: relative;
  button {
    width: 50px;
    height: 50px;
    background-color: #f8f7fa;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cart-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ff4d4f;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}

.custom-table {
  table {
    border-spacing: 0;
    border-collapse: separate;

    thead {
      :first-child {
        th {
          padding: 20px 0;
          border-bottom: 0;

          &:first-child {
            border-radius: 15px 0 0 0;
          }

          &:last-child {
            border-radius: 0 15px 0 0;
          }
        }
      }

      tr {
        background-color: #fff;

        th {
          color: #4b465c;
          font-weight: 600;
          font-size: 13px;
          padding: 5px;
          text-align: center;
          vertical-align: middle;
          border-bottom: $border-option;
          border-top: $border-option;

          &:first-child {
            padding-left: 15px;
            border-left: $border-option;
          }

          &:last-child {
            border-right: $border-option;
          }
        }
      }
    }

    tbody {
      :last-child {
        td {
          &:last-child {
            border-radius: 0 0 15px 0;
          }

          &:first-child {
            border-radius: 0 0 0 15px;
          }
        }
      }

      tr {
        background-color: #fff;
        height: 50px;
      }

      td {
        color: rgba(75, 70, 92, 0.8) !important;
        font-size: 15px;
        font-weight: 500;
        vertical-align: middle;
        text-align: center;
        border-top: $border-option;
        border-bottom: $border-option;
        padding: 10px 5px;

        &:last-child {
          border-right: $border-option;

          i {
            font-size: 20px;
          }
        }

        &:first-child {
          width: 55px;
          border-left: $border-option;
        }

        .form-switch {
          text-align: start;
        }
      }
    }
  }
}

.total-table {
  tbody {
    :last-child {
      td {
        &:last-child {
          border-radius: 0 !important;
        }

        &:first-child {
          border-radius: 0 !important;
        }
      }
    }
  }

  tfoot {
    td {
      background-color: #fff;
      padding: 5px;
      border-bottom: $border-option;

      &:last-child {
        border-right: $border-option;
      }

      &:first-child {
        border-left: $border-option;
      }
    }

    :last-child {
      td {
        &:last-child {
          border-bottom: $border-option;
          border-radius: 0 0 15px 0;
        }

        &:first-child {
          border-bottom: $border-option;
          border-radius: 0 0 0 15px;
        }
      }
    }
  }
}

.product-table {
  thead {
    :first-child {
      th {
        &:last-child {
          text-align: end;
          padding-right: 15px;
        }
      }
    }
  }

  .product-img {
    position: relative;
    width: 195px;
    height: 130px;

    img {
      width: 100%;
      object-fit: cover;
    }

    .view-btn {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: 75% !important;
    }
  }
}

.navigator {
  border-bottom: 2px solid #dee2e6;

  li {
    font-weight: 600;
    position: relative;

    &::after {
      content: "";
      display: none;
      width: 100%;
      height: 2px;
      background-color: #000;
      position: absolute;
      bottom: -2px;
      left: 0;
    }

    &.active {
      &::after {
        display: block;
      }
    }
  }
}

/* end global tables design*/
/* start global summery design*/

.summery {
  .card-header {
    padding: 0;
    margin-bottom: 0;
    color: #000;
    background-color: #fff;
    border-bottom: 0;

    input[type="text"] {
      cursor: pointer;
    }
  }

  .small-card {
    .icon {
      padding: 7px;
      color: #fff;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 20px;
      font-weight: 600;
    }

    .KD {
      color: $dark;
      font-size: 24px;
      font-weight: 600;
    }

    .info {
      color: #19191c;
      font-size: 13px;
      font-weight: 500;
    }

    .ratio {
      color: #34b5ec;
      font-size: 11px;
      font-weight: 500;
    }

    &.today {
      background-color: rgba(255, 226, 229, 1);

      .icon {
        background-color: #fa5a7d;
      }
    }

    &.month {
      background-color: #fff9ef;

      .icon {
        background-color: rgba(1, 59, 250, 0.6);
      }
    }

    &.year {
      background-color: rgba(237, 253, 243, 1);

      .icon {
        background-color: rgba(241, 28, 177, 0.6);
      }
    }
  }
}

.currentPic {
  border: 1px dashed rgba(165, 152, 152, 1);
  overflow: hidden;
  border-radius: 10px;
}

/* end global summery design*/
/* start global tabset design */

.nav-tabs {
  border: 0;
  border-bottom: 2px solid $gray;

  .nav-item {
    display: inline-block;
    padding: 5px 9px;

    .nav-link {
      padding: 0 !important;
      position: relative;
      border: 0;
      color: #000;
      font-weight: 600;
      font-size: 16px;

      &::after {
        content: "";
        display: none;
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -6px;
        left: 0;
        background-color: #000;
      }

      &.active,
      &:hover {
        &::after {
          display: block;
        }
      }
    }
  }
}

/* end global tabset design */

input[name="password"] {
  position: relative;
  padding-right: 30px;
  & + .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* start global progress-bar design*/

.progress {
  background-color: rgba(75, 70, 92, 0.08);
  height: 8px;

  .progress-bar {
    background-color: #4b465c;
    box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
  }
}

.chart__list {
  display: flex;

  li a {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    border: 1px dashed rgb(160 160 160);
    border-radius: 5px;
    margin-right: 1rem;

    span {
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 35px;
      border-radius: 5px;
      display: block;
      background: rgba(75, 70, 92, 0.08);
      margin-bottom: 0.5rem;
    }

    &.active {
      border: 1px solid #4b465c;
    }
  }
}

/* end global progress-bar design*/
/* start global pagination design*/

.pagination {
  justify-content: end;
  .page-item {
    .page-link {
      color: #000;
      &:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
      }
    }
    &.active {
      .page-link {
        color: #fff;
        background-color: #000;
        border-color: #000;
      }
    }
  }
}

/* end global pagination design*/

@media (max-width: 576px) {
  .btn + .btn:hover {
    font-size: 11px;
  }

  .product-card {
    .product-info {
      .short-desc {
        width: 60px;
      }
      .product-name {
        font-size: 12px;
        width: 60px;
      }
    }
    button {
      font-size: 13px;
    }
  }
}

.company-info {
  .company-avatar {
    display: inline-block;
    border-radius: 50%;
    width: 68px;
    height: 68px;
    padding: 6px;
    background-color: #f067b9;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .company-name {
    h4 {
      font-size: 24.698px;
      color: $dark;
      font-weight: 700;
    }
  }
}

.add-brand-modal {
  max-width: 700px;
}
