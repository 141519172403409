$flaticon_mycollection-font: "flaticon_mycollection";

@font-face {
    font-family: $flaticon_mycollection-font;
    src: url("./flaticon_mycollection.woff2?e00bc78d8d24acd68774c5f7664fa544") format("woff2"),
url("./flaticon_mycollection.woff?e00bc78d8d24acd68774c5f7664fa544") format("woff"),
url("./flaticon_mycollection.eot?e00bc78d8d24acd68774c5f7664fa544#iefix") format("embedded-opentype"),
url("./flaticon_mycollection.ttf?e00bc78d8d24acd68774c5f7664fa544") format("truetype"),
url("./flaticon_mycollection.svg?e00bc78d8d24acd68774c5f7664fa544#flaticon_mycollection") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_mycollection !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$flaticon_mycollection-map: (
    "home": "\f101",
    "shopping-cart": "\f102",
    "user": "\f103",
    "management": "\f104",
    "celebrity": "\f105",
    "categories": "\f106",
    "category": "\f107",
    "inventory": "\f108",
    "setting": "\f109",
    "webpage": "\f10a",
    "megaphone": "\f10b",
    "megaphone-1": "\f10c",
    "settings": "\f10d",
    "settings-1": "\f10e",
    "settings-2": "\f10f",
    "comment": "\f110",
    "chat": "\f111",
    "edit-comment": "\f112",
    "comment-1": "\f113",
    "crown": "\f114",
    "setting-1": "\f115",
    "user-1": "\f116",
    "graph-analysis": "\f117",
    "bar-chart": "\f118",
    "bar-chart-1": "\f119",
    "pie-chart": "\f11a",
    "dollar-sign": "\f11b",
    "mail": "\f11c",
    "link": "\f11d",
    "link-1": "\f11e",
    "cursor": "\f11f",
    "group": "\f120",
    "group-1": "\f121",
    "danger": "\f122",
    "warning": "\f123",
    "ban": "\f124",
    "shadow": "\f125",
    "link-2": "\f126",
    "external-link": "\f127",
    "discount": "\f128",
    "star": "\f129",
    "star-1": "\f12a",
    "night-mode": "\f12b",
    "night-mode-1": "\f12c",
    "file": "\f12d",
    "discount-1": "\f12e",
    "view": "\f12f",
    "visible": "\f130",
    "file-1": "\f131",
    "exclamation": "\f132",
    "pen": "\f133",
    "pencil": "\f134",
    "calendar": "\f135",
    "filter": "\f136",
    "sorting": "\f137",
    "funnel": "\f138",
    "more": "\f139",
    "down": "\f13a",
    "angle-right": "\f13b",
    "search": "\f13c",
    "search-interface-symbol": "\f13d",
    "printer": "\f13e",
    "printing": "\f13f",
    "delete": "\f140",
    "box": "\f141",
    "box-1": "\f142",
    "package": "\f143",
    "package-box": "\f144",
    "invoice": "\f145",
    "plus": "\f146",
    "add-user": "\f147",
    "add-user-1": "\f148",
    "phone-call": "\f149",
    "calendar-1": "\f14a",
    "edit": "\f14b",
    "editing": "\f14c",
    "pin": "\f14d",
    "wallet": "\f14e",
    "wallet-1": "\f14f",
    "top-brand": "\f150",
    "price-tag": "\f151",
    "upload": "\f152",
    "withdraw": "\f153",
    "makeup": "\f154",
    "clothes-hanger": "\f155",
    "perfume": "\f156",
    "review": "\f157",
    "bell": "\f158",
    "notification": "\f159",
    "expired": "\f15a",
    "shopping-bag": "\f15b",
    "camera": "\f15c",
    "credit-cards-payment": "\f15d",
    "management-1": "\f15e",
    "hide": "\f15f",
    "image": "\f160",
    "menu": "\f161",
    "text-document": "\f162",
    "plus-1": "\f163",
    "minus-square-outlined-button": "\f164",
    "fast-delivery": "\f165",
    "time-left": "\f166",
    "heart": "\f167",
    "heart-1": "\f168",
    "heart-2": "\f169",
    "maps-and-flags": "\f16a",
    "placeholder": "\f16b",
    "product-return": "\f16c",
    "users": "\f16d",
    "shopping-cart-1": "\f16e",
    "pie-chart-1": "\f16f",
    "checklist": "\f170",
    "marketing-agent": "\f171",
    "package-1": "\f172",
    "git": "\f173",
    "exchange": "\f174",
    "double-arrows": "\f175",
    "moon": "\f176",
    "info-button": "\f177",
    "cancel": "\f178",
    "close": "\f179",
    "close-1": "\f17a",
    "tag": "\f17b",
    "money": "\f17c",
    "remove": "\f17d",
    "undo": "\f17e",
    "close-2": "\f17f",
    "close-3": "\f180",
    "close-4": "\f181",
    "ticket": "\f182",
    "money-1": "\f183",
    "check": "\f184",
    "right-arrow": "\f185",
    "up-arrow": "\f186",
    "down-arrow": "\f187",
    "down-right": "\f188",
    "right-arrow-1": "\f189",
    "left-arrow": "\f18a",
    "top": "\f18b",
    "gift": "\f18c",
    "pyramid": "\f18d",
    "exit": "\f18e",
    "credit-card": "\f18f",
    "reveneu": "\f190",
    "api": "\f191",
    "puzzle": "\f192",
    "test": "\f193",
    "ask": "\f194",
);

.flaticon-home:before {
    content: map-get($flaticon_mycollection-map, "home");
}
.flaticon-shopping-cart:before {
    content: map-get($flaticon_mycollection-map, "shopping-cart");
}
.flaticon-user:before {
    content: map-get($flaticon_mycollection-map, "user");
}
.flaticon-management:before {
    content: map-get($flaticon_mycollection-map, "management");
}
.flaticon-celebrity:before {
    content: map-get($flaticon_mycollection-map, "celebrity");
}
.flaticon-categories:before {
    content: map-get($flaticon_mycollection-map, "categories");
}
.flaticon-category:before {
    content: map-get($flaticon_mycollection-map, "category");
}
.flaticon-inventory:before {
    content: map-get($flaticon_mycollection-map, "inventory");
}
.flaticon-setting:before {
    content: map-get($flaticon_mycollection-map, "setting");
}
.flaticon-webpage:before {
    content: map-get($flaticon_mycollection-map, "webpage");
}
.flaticon-megaphone:before {
    content: map-get($flaticon_mycollection-map, "megaphone");
}
.flaticon-megaphone-1:before {
    content: map-get($flaticon_mycollection-map, "megaphone-1");
}
.flaticon-settings:before {
    content: map-get($flaticon_mycollection-map, "settings");
}
.flaticon-settings-1:before {
    content: map-get($flaticon_mycollection-map, "settings-1");
}
.flaticon-settings-2:before {
    content: map-get($flaticon_mycollection-map, "settings-2");
}
.flaticon-comment:before {
    content: map-get($flaticon_mycollection-map, "comment");
}
.flaticon-chat:before {
    content: map-get($flaticon_mycollection-map, "chat");
}
.flaticon-edit-comment:before {
    content: map-get($flaticon_mycollection-map, "edit-comment");
}
.flaticon-comment-1:before {
    content: map-get($flaticon_mycollection-map, "comment-1");
}
.flaticon-crown:before {
    content: map-get($flaticon_mycollection-map, "crown");
}
.flaticon-setting-1:before {
    content: map-get($flaticon_mycollection-map, "setting-1");
}
.flaticon-user-1:before {
    content: map-get($flaticon_mycollection-map, "user-1");
}
.flaticon-graph-analysis:before {
    content: map-get($flaticon_mycollection-map, "graph-analysis");
}
.flaticon-bar-chart:before {
    content: map-get($flaticon_mycollection-map, "bar-chart");
}
.flaticon-bar-chart-1:before {
    content: map-get($flaticon_mycollection-map, "bar-chart-1");
}
.flaticon-pie-chart:before {
    content: map-get($flaticon_mycollection-map, "pie-chart");
}
.flaticon-dollar-sign:before {
    content: map-get($flaticon_mycollection-map, "dollar-sign");
}
.flaticon-mail:before {
    content: map-get($flaticon_mycollection-map, "mail");
}
.flaticon-link:before {
    content: map-get($flaticon_mycollection-map, "link");
}
.flaticon-link-1:before {
    content: map-get($flaticon_mycollection-map, "link-1");
}
.flaticon-cursor:before {
    content: map-get($flaticon_mycollection-map, "cursor");
}
.flaticon-group:before {
    content: map-get($flaticon_mycollection-map, "group");
}
.flaticon-group-1:before {
    content: map-get($flaticon_mycollection-map, "group-1");
}
.flaticon-danger:before {
    content: map-get($flaticon_mycollection-map, "danger");
}
.flaticon-warning:before {
    content: map-get($flaticon_mycollection-map, "warning");
}
.flaticon-ban:before {
    content: map-get($flaticon_mycollection-map, "ban");
}
.flaticon-shadow:before {
    content: map-get($flaticon_mycollection-map, "shadow");
}
.flaticon-link-2:before {
    content: map-get($flaticon_mycollection-map, "link-2");
}
.flaticon-external-link:before {
    content: map-get($flaticon_mycollection-map, "external-link");
}
.flaticon-discount:before {
    content: map-get($flaticon_mycollection-map, "discount");
}
.flaticon-star:before {
    content: map-get($flaticon_mycollection-map, "star");
}
.flaticon-star-1:before {
    content: map-get($flaticon_mycollection-map, "star-1");
}
.flaticon-night-mode:before {
    content: map-get($flaticon_mycollection-map, "night-mode");
}
.flaticon-night-mode-1:before {
    content: map-get($flaticon_mycollection-map, "night-mode-1");
}
.flaticon-file:before {
    content: map-get($flaticon_mycollection-map, "file");
}
.flaticon-discount-1:before {
    content: map-get($flaticon_mycollection-map, "discount-1");
}
.flaticon-view:before {
    content: map-get($flaticon_mycollection-map, "view");
}
.flaticon-visible:before {
    content: map-get($flaticon_mycollection-map, "visible");
}
.flaticon-file-1:before {
    content: map-get($flaticon_mycollection-map, "file-1");
}
.flaticon-exclamation:before {
    content: map-get($flaticon_mycollection-map, "exclamation");
}
.flaticon-pen:before {
    content: map-get($flaticon_mycollection-map, "pen");
}
.flaticon-pencil:before {
    content: map-get($flaticon_mycollection-map, "pencil");
}
.flaticon-calendar:before {
    content: map-get($flaticon_mycollection-map, "calendar");
}
.flaticon-filter:before {
    content: map-get($flaticon_mycollection-map, "filter");
}
.flaticon-sorting:before {
    content: map-get($flaticon_mycollection-map, "sorting");
}
.flaticon-funnel:before {
    content: map-get($flaticon_mycollection-map, "funnel");
}
.flaticon-more:before {
    content: map-get($flaticon_mycollection-map, "more");
}
.flaticon-down:before {
    content: map-get($flaticon_mycollection-map, "down");
}
.flaticon-angle-right:before {
    content: map-get($flaticon_mycollection-map, "angle-right");
}
.flaticon-search:before {
    content: map-get($flaticon_mycollection-map, "search");
}
.flaticon-search-interface-symbol:before {
    content: map-get($flaticon_mycollection-map, "search-interface-symbol");
}
.flaticon-printer:before {
    content: map-get($flaticon_mycollection-map, "printer");
}
.flaticon-printing:before {
    content: map-get($flaticon_mycollection-map, "printing");
}
.flaticon-delete:before {
    content: map-get($flaticon_mycollection-map, "delete");
}
.flaticon-box:before {
    content: map-get($flaticon_mycollection-map, "box");
}
.flaticon-box-1:before {
    content: map-get($flaticon_mycollection-map, "box-1");
}
.flaticon-package:before {
    content: map-get($flaticon_mycollection-map, "package");
}
.flaticon-package-box:before {
    content: map-get($flaticon_mycollection-map, "package-box");
}
.flaticon-invoice:before {
    content: map-get($flaticon_mycollection-map, "invoice");
}
.flaticon-plus:before {
    content: map-get($flaticon_mycollection-map, "plus");
}
.flaticon-add-user:before {
    content: map-get($flaticon_mycollection-map, "add-user");
}
.flaticon-add-user-1:before {
    content: map-get($flaticon_mycollection-map, "add-user-1");
}
.flaticon-phone-call:before {
    content: map-get($flaticon_mycollection-map, "phone-call");
}
.flaticon-calendar-1:before {
    content: map-get($flaticon_mycollection-map, "calendar-1");
}
.flaticon-edit:before {
    content: map-get($flaticon_mycollection-map, "edit");
}
.flaticon-editing:before {
    content: map-get($flaticon_mycollection-map, "editing");
}
.flaticon-pin:before {
    content: map-get($flaticon_mycollection-map, "pin");
}
.flaticon-wallet:before {
    content: map-get($flaticon_mycollection-map, "wallet");
}
.flaticon-wallet-1:before {
    content: map-get($flaticon_mycollection-map, "wallet-1");
}
.flaticon-top-brand:before {
    content: map-get($flaticon_mycollection-map, "top-brand");
}
.flaticon-price-tag:before {
    content: map-get($flaticon_mycollection-map, "price-tag");
}
.flaticon-upload:before {
    content: map-get($flaticon_mycollection-map, "upload");
}
.flaticon-withdraw:before {
    content: map-get($flaticon_mycollection-map, "withdraw");
}
.flaticon-makeup:before {
    content: map-get($flaticon_mycollection-map, "makeup");
}
.flaticon-clothes-hanger:before {
    content: map-get($flaticon_mycollection-map, "clothes-hanger");
}
.flaticon-perfume:before {
    content: map-get($flaticon_mycollection-map, "perfume");
}
.flaticon-review:before {
    content: map-get($flaticon_mycollection-map, "review");
}
.flaticon-bell:before {
    content: map-get($flaticon_mycollection-map, "bell");
}
.flaticon-notification:before {
    content: map-get($flaticon_mycollection-map, "notification");
}
.flaticon-expired:before {
    content: map-get($flaticon_mycollection-map, "expired");
}
.flaticon-shopping-bag:before {
    content: map-get($flaticon_mycollection-map, "shopping-bag");
}
.flaticon-camera:before {
    content: map-get($flaticon_mycollection-map, "camera");
}
.flaticon-credit-cards-payment:before {
    content: map-get($flaticon_mycollection-map, "credit-cards-payment");
}
.flaticon-management-1:before {
    content: map-get($flaticon_mycollection-map, "management-1");
}
.flaticon-hide:before {
    content: map-get($flaticon_mycollection-map, "hide");
}
.flaticon-image:before {
    content: map-get($flaticon_mycollection-map, "image");
}
.flaticon-menu:before {
    content: map-get($flaticon_mycollection-map, "menu");
}
.flaticon-text-document:before {
    content: map-get($flaticon_mycollection-map, "text-document");
}
.flaticon-plus-1:before {
    content: map-get($flaticon_mycollection-map, "plus-1");
}
.flaticon-minus-square-outlined-button:before {
    content: map-get($flaticon_mycollection-map, "minus-square-outlined-button");
}
.flaticon-fast-delivery:before {
    content: map-get($flaticon_mycollection-map, "fast-delivery");
}
.flaticon-time-left:before {
    content: map-get($flaticon_mycollection-map, "time-left");
}
.flaticon-heart:before {
    content: map-get($flaticon_mycollection-map, "heart");
}
.flaticon-heart-1:before {
    content: map-get($flaticon_mycollection-map, "heart-1");
}
.flaticon-heart-2:before {
    content: map-get($flaticon_mycollection-map, "heart-2");
}
.flaticon-maps-and-flags:before {
    content: map-get($flaticon_mycollection-map, "maps-and-flags");
}
.flaticon-placeholder:before {
    content: map-get($flaticon_mycollection-map, "placeholder");
}
.flaticon-product-return:before {
    content: map-get($flaticon_mycollection-map, "product-return");
}
.flaticon-users:before {
    content: map-get($flaticon_mycollection-map, "users");
}
.flaticon-shopping-cart-1:before {
    content: map-get($flaticon_mycollection-map, "shopping-cart-1");
}
.flaticon-pie-chart-1:before {
    content: map-get($flaticon_mycollection-map, "pie-chart-1");
}
.flaticon-checklist:before {
    content: map-get($flaticon_mycollection-map, "checklist");
}
.flaticon-marketing-agent:before {
    content: map-get($flaticon_mycollection-map, "marketing-agent");
}
.flaticon-package-1:before {
    content: map-get($flaticon_mycollection-map, "package-1");
}
.flaticon-git:before {
    content: map-get($flaticon_mycollection-map, "git");
}
.flaticon-exchange:before {
    content: map-get($flaticon_mycollection-map, "exchange");
}
.flaticon-double-arrows:before {
    content: map-get($flaticon_mycollection-map, "double-arrows");
}
.flaticon-moon:before {
    content: map-get($flaticon_mycollection-map, "moon");
}
.flaticon-info-button:before {
    content: map-get($flaticon_mycollection-map, "info-button");
}
.flaticon-cancel:before {
    content: map-get($flaticon_mycollection-map, "cancel");
}
.flaticon-close:before {
    content: map-get($flaticon_mycollection-map, "close");
}
.flaticon-close-1:before {
    content: map-get($flaticon_mycollection-map, "close-1");
}
.flaticon-tag:before {
    content: map-get($flaticon_mycollection-map, "tag");
}
.flaticon-money:before {
    content: map-get($flaticon_mycollection-map, "money");
}
.flaticon-remove:before {
    content: map-get($flaticon_mycollection-map, "remove");
}
.flaticon-undo:before {
    content: map-get($flaticon_mycollection-map, "undo");
}
.flaticon-close-2:before {
    content: map-get($flaticon_mycollection-map, "close-2");
}
.flaticon-close-3:before {
    content: map-get($flaticon_mycollection-map, "close-3");
}
.flaticon-close-4:before {
    content: map-get($flaticon_mycollection-map, "close-4");
}
.flaticon-ticket:before {
    content: map-get($flaticon_mycollection-map, "ticket");
}
.flaticon-money-1:before {
    content: map-get($flaticon_mycollection-map, "money-1");
}
.flaticon-check:before {
    content: map-get($flaticon_mycollection-map, "check");
}
.flaticon-right-arrow:before {
    content: map-get($flaticon_mycollection-map, "right-arrow");
}
.flaticon-up-arrow:before {
    content: map-get($flaticon_mycollection-map, "up-arrow");
}
.flaticon-down-arrow:before {
    content: map-get($flaticon_mycollection-map, "down-arrow");
}
.flaticon-down-right:before {
    content: map-get($flaticon_mycollection-map, "down-right");
}
.flaticon-right-arrow-1:before {
    content: map-get($flaticon_mycollection-map, "right-arrow-1");
}
.flaticon-left-arrow:before {
    content: map-get($flaticon_mycollection-map, "left-arrow");
}
.flaticon-top:before {
    content: map-get($flaticon_mycollection-map, "top");
}
.flaticon-gift:before {
    content: map-get($flaticon_mycollection-map, "gift");
}
.flaticon-pyramid:before {
    content: map-get($flaticon_mycollection-map, "pyramid");
}
.flaticon-exit:before {
    content: map-get($flaticon_mycollection-map, "exit");
}
.flaticon-credit-card:before {
    content: map-get($flaticon_mycollection-map, "credit-card");
}
.flaticon-reveneu:before {
    content: map-get($flaticon_mycollection-map, "reveneu");
}
.flaticon-api:before {
    content: map-get($flaticon_mycollection-map, "api");
}
.flaticon-puzzle:before {
    content: map-get($flaticon_mycollection-map, "puzzle");
}
.flaticon-test:before {
    content: map-get($flaticon_mycollection-map, "test");
}
.flaticon-ask:before {
    content: map-get($flaticon_mycollection-map, "ask");
}
